import { AlphaStack, Box } from "@shopify/polaris";
import {
  estimatedTitles,
  getOrderEventTimelineItems,
  getTimelineItems,
  Timeline,
  TimelineItem,
} from "common/components/Timeline";
import { getLatestEstimatedTimes } from "common/helpers/OrderEvents/helpers/getLatestEstimatedTimes";
import { DeserializedOrderSummary, FulfillmentShipmentWithoutAddress, ItemWithProductData } from "common/types";
import { MappedTrackingDetail } from "common/types/MappedTrackingDetail";
import { filterInvalidShipments } from "common/utils/shipment";
import { CarrierOnlyEstimationData } from "~/common/types/CarrierOnlyEstimationData";
import { convertCarrierDateToUTCDate } from "~/common/utils/date";
import { Shipment } from "./Shipment";
import { ShipmentItems } from "./ShipmentItems";

interface OrderShipmentsProps {
  shipments: FulfillmentShipmentWithoutAddress[];
  originalItems: ItemWithProductData[];
  destinationTz: string;
  trackingDetailMap: MappedTrackingDetail;
  orderEvents: DeserializedOrderSummary["events"];
  carrierOnlyEstimationData?: CarrierOnlyEstimationData;
}

const getTimelineMarkup = (
  shipment: FulfillmentShipmentWithoutAddress,
  trackingDetailMap: MappedTrackingDetail,
  orderEvents: DeserializedOrderSummary["events"],
  estimatedTimes: any,
  destinationTz: string,
  carrierOnlyEstimationData?: CarrierOnlyEstimationData
) => {
  const packageTracking = shipment?.packages[0]?.trackingCode;
  const labelTracking = shipment?.labels[0]?.trackingCode;
  const trackingCode = packageTracking || labelTracking;
  let timelineItems: TimelineItem[] | undefined;

  if (!trackingCode) {
    timelineItems = getOrderEventTimelineItems(orderEvents, estimatedTimes);
  } else {
    const trackingDetail = trackingDetailMap[trackingCode] || {};
    timelineItems = getTimelineItems(trackingDetail, estimatedTimes, orderEvents);
  }

  const updatedTimelineItems = timelineItems.map((item) => {
    if (item.title === estimatedTitles.DELIVERED) {
      // if the carrier only estimation feature is on and status is delayed, remove the date from the timeline item
      if (carrierOnlyEstimationData?.shouldHideEstimatedDeliveryDate) {
        const { date, ...rest } = item;
        return rest;
      }
      // else make sure convert the date to UTC. Note we are not altering other item in the timeline as those already come converted in UTC.
      return {
        ...item,
        date: item.date ? convertCarrierDateToUTCDate(item.date, shipment.shippingMethod, destinationTz) : item.date,
      };
    }
    return item;
  });

  return updatedTimelineItems ? (
    <Timeline
      items={updatedTimelineItems}
      destinationTz={destinationTz}
      carrierOnlyEstimationData={carrierOnlyEstimationData}
    />
  ) : null;
};

export const Shipments: React.FC<OrderShipmentsProps> = ({
  shipments = [],
  originalItems,
  destinationTz,
  trackingDetailMap,
  orderEvents,
  carrierOnlyEstimationData,
}) => {
  const estimatedTimes = getLatestEstimatedTimes(shipments);
  const isMultiShipment = shipments.length > 1;

  const timelineMarkup = !isMultiShipment
    ? getTimelineMarkup(
        shipments[0],
        trackingDetailMap,
        orderEvents,
        estimatedTimes,
        destinationTz,
        carrierOnlyEstimationData
      )
    : null;

  const shipmentMarkup = shipments.length ? (
    <AlphaStack gap="10" fullWidth>
      {filterInvalidShipments(shipments).map((shipment: FulfillmentShipmentWithoutAddress) => (
        <Shipment
          shipment={shipment}
          originalItems={originalItems}
          destinationTz={destinationTz}
          trackingDetailMap={trackingDetailMap}
          isSingleShipment={!isMultiShipment}
          key={shipment.id}
        />
      ))}
    </AlphaStack>
  ) : (
    <ShipmentItems items={originalItems} originalItems={originalItems} destinationTz={destinationTz} />
  );

  return (
    <Box paddingBlockStart="6" width="100%">
      <AlphaStack as="div" align="start" gap="10" fullWidth>
        {timelineMarkup}
        {shipmentMarkup}
      </AlphaStack>
    </Box>
  );
};
