import { DeserializedOrderSummary, OrderEventType, TrackingDetailResponse } from "common/types";
import { getDeliveryTimeTz } from "~/common/helpers/OrderEvents/helpers/timezones";
import { BuyerPortalFeature, isFeatureEnabled } from "~/common/server/feature.server";
import { CarrierOnlyEstimationData } from "~/common/types/CarrierOnlyEstimationData";
import { TrackingEventStatusToOrderEventType } from "~/common/types/maps";
import { Status } from "~/common/types/Status";
import { CARRIER_TRACKING_CODE_URLS } from "../carrier";
import {
  convertCarrierDateToUTCDate,
  formatDate,
  getDeliveredDate,
  getShippedDate,
  isDateToday,
  isUntilDate,
} from "../date";
import { OrderEventTypeIcon, OrderEventTypeText } from "../order";
import horizontalComplete from "../progress/horizontalComplete.svg";
import horizontalNotStarted from "../progress/horizontalNotStarted.svg";
import horizontalProgress from "../progress/horizontalProgress.svg";

const DELAY_MESSAGE = {
  Default:
    "Sorry, We're experiencing a delay in delivery. Please click on the carrier link below to get updated estimates.",
  CarriersWithoutLink:
    "Sorry, We're experiencing a delay in delivery. Please check with the carrier website for updated estimates.",
};

/**
 * Get the arrival time text based on the estimated delivery time and whether the delivery is on time.
 */
function getArrivalTimeText(
  estimatedDeliveryTime: Date,
  destinationTz: string,
  promisedDeliveryTime?: Date,
  trackingDetails?: TrackingDetailResponse,
  carrierOnlyEstimationData?: CarrierOnlyEstimationData
): string {
  const now = new Date();
  const shouldOnlyShowFromCarrier = carrierOnlyEstimationData?.isCarrierOnlyEstimationsFeatureOn;

  // Try to use estimatedDeliveryTime from Transportation first, if it is available. It may be more
  // up to date than the estimatedDeliveryTime which comes from deserializedOrder
  if (
    trackingDetails &&
    trackingDetails.estimatedDeliveryTime &&
    isUntilDate(
      now,
      convertCarrierDateToUTCDate(trackingDetails.estimatedDeliveryTime, trackingDetails.shippingMethod, destinationTz)
    )
  ) {
    // trackingDetails.estimatedDeliveryTime can come in local time with Z format, so we need to convert it to UTC
    return `Estimated to arrive ${getShippedDate(
      convertCarrierDateToUTCDate(trackingDetails.estimatedDeliveryTime, trackingDetails.shippingMethod, destinationTz),
      destinationTz
    )}`;
  }

  if (shouldOnlyShowFromCarrier && !carrierOnlyEstimationData.isCarrierEstimationAvailable) {
    return "Delivery date to be confirmed after shipment pickup.";
  }

  if (!shouldOnlyShowFromCarrier) {
    if (estimatedDeliveryTime && isUntilDate(now, estimatedDeliveryTime)) {
      return `Estimated to arrive ${getShippedDate(estimatedDeliveryTime, destinationTz)}`;
    }
    if (promisedDeliveryTime && isUntilDate(now, promisedDeliveryTime)) {
      return `Arrives ${getShippedDate(promisedDeliveryTime, destinationTz)}`;
    }
  }

  const carrier = trackingDetails ? trackingDetails.shippingMethod.split(".")[0] : undefined;
  if (carrier && !CARRIER_TRACKING_CODE_URLS.hasOwnProperty(carrier)) {
    return DELAY_MESSAGE.CarriersWithoutLink;
  }
  return DELAY_MESSAGE.Default;
}

export function getStatusFromTrackingDetail(trackingDetail: TrackingDetailResponse): Status {
  const estimatedDeliveryTime = trackingDetail.estimatedDeliveryTime;
  const currentEvent = trackingDetail.trackingHistory.length
    ? trackingDetail.trackingHistory[trackingDetail.trackingHistory.length - 1]
    : undefined;
  const type = currentEvent ? TrackingEventStatusToOrderEventType[currentEvent.status] : OrderEventType.CREATED;
  const base = {
    heading: OrderEventTypeText[type],
    icon: OrderEventTypeIcon[type],
    isToday: false,
  };

  const estimatedByText = estimatedDeliveryTime
    ? `Estimated by ${formatDate(estimatedDeliveryTime!, undefined)}`
    : "Estimating delivery time...";

  switch (type) {
    case OrderEventType.CREATED:
      return {
        ...base,
        background: "surface-neutral",
        body: estimatedByText,
        step: 0,
      };
    case OrderEventType.INGESTION:
    case OrderEventType.PROCESSING:
      return {
        ...base,
        background: "surface-highlight-subdued",
        body: estimatedByText,
        step: 1,
      };
    case OrderEventType.SHIPPED:
    case OrderEventType.IN_TRANSIT:
    case OrderEventType.TRACKING_REPORTED:
    case OrderEventType.REPLACEMENT_SHIPPED:
      const toDeliverToday = isDateToday(estimatedDeliveryTime!);

      return {
        ...base,
        background: "surface-highlight-subdued",
        body: estimatedByText,
        step: toDeliverToday ? 3 : 2,
        isToday: toDeliverToday,
      };
    case OrderEventType.PARTIALLY_FULFILLED:
    case OrderEventType.DELIVERED:
      const deliveredToday = isDateToday(currentEvent!.eventTime);

      return {
        ...base,
        background: deliveredToday ? "surface-success-subdued" : "background",
        body: `Delivered ${getDeliveredDate(currentEvent!.eventTime, undefined)}`,
        step: 5,
        isToday: deliveredToday,
      };
    case OrderEventType.CANCELLED:
      return {
        ...base,
        background: "background",
        body: `Cancelled ${formatDate(currentEvent!.eventTime, undefined)}`,
        step: 0,
      };
    default:
      return {
        ...base,
        background: "surface-critical",
        body: "Contact seller",
        step: 0,
      };
  }
}

export function getOrderStatus(
  deserializedOrder: DeserializedOrderSummary,
  trackingDetails?: TrackingDetailResponse,
  carrierOnlyEstimationData?: CarrierOnlyEstimationData
): Status {
  const { estimatedDeliveryTime, currentEvent, isDeliveryOnTime, promisedDeliveryTime, order } = deserializedOrder;
  const type = currentEvent?.type || OrderEventType.CREATED;
  const base = {
    heading: OrderEventTypeText[type],
    icon: OrderEventTypeIcon[type],
    isToday: false,
  };
  const shouldOnlyShowFromCarrier = carrierOnlyEstimationData?.isCarrierOnlyEstimationsFeatureOn;
  const destinationTz = getDeliveryTimeTz(order.destinationTz);

  // Estimated by text is displayed during order created, ingestion, and processing states
  const estimatedByText =
    estimatedDeliveryTime && isDeliveryOnTime && !shouldOnlyShowFromCarrier
      ? `Estimated by ${formatDate(estimatedDeliveryTime!, destinationTz)}`
      : "Delivery date to be confirmed after shipment pickup.";

  const arrivalTimeText = estimatedDeliveryTime
    ? getArrivalTimeText(
        estimatedDeliveryTime,
        destinationTz,
        promisedDeliveryTime,
        trackingDetails,
        carrierOnlyEstimationData
      )
    : estimatedByText;

  switch (type) {
    case OrderEventType.CREATED:
      return {
        ...base,
        background: "surface-neutral",
        body: estimatedByText,
        step: 0,
      };
    case OrderEventType.INGESTION:
    case OrderEventType.PROCESSING:
      return {
        ...base,
        background: "surface-highlight-subdued",
        body: estimatedByText,
        step: 1,
      };
    case OrderEventType.SHIPPED:
    case OrderEventType.IN_TRANSIT:
    case OrderEventType.TRACKING_REPORTED:
    case OrderEventType.REPLACEMENT_SHIPPED:
      const toDeliverToday = isDateToday(estimatedDeliveryTime!);

      return {
        ...base,
        background: "surface-highlight-subdued",
        body: arrivalTimeText,
        step: toDeliverToday ? 3 : 2,
        isToday: toDeliverToday,
      };
    case OrderEventType.PARTIALLY_FULFILLED:
    case OrderEventType.DELIVERED:
      const deliveredToday = isDateToday(currentEvent!.time);

      return {
        ...base,
        background: deliveredToday ? "surface-success-subdued" : "background",
        body: `Delivered ${getDeliveredDate(currentEvent!.time, destinationTz)}`,
        step: 5,
        isToday: deliveredToday,
      };
    case OrderEventType.CANCELLED:
      return {
        ...base,
        background: "background",
        body: `Cancelled ${formatDate(currentEvent!.time, destinationTz)}`,
        step: 0,
      };
    default:
      return {
        ...base,
        background: "surface-critical",
        body: "Contact seller",
        step: 0,
      };
  }
}

export function getProgressIcon(currentStep: number, index: number) {
  if (currentStep === index) {
    return horizontalProgress;
  }
  return index < currentStep ? horizontalComplete : horizontalNotStarted;
}
