import { datadogRum } from "@datadog/browser-rum";
import { Box, Frame, Inline, Layout } from "@shopify/polaris";
import { DeserializedOrderSummary, OrderStatus } from "common/types";
import { MappedTrackingDetail, TrackingDetailResponse } from "common/types/MappedTrackingDetail";
import { OrderStatusCard } from "./OrderStatusCard";
import { TopBar } from "./TopBar";

import { DeliveryMethod } from "common/components/DeliveryMethod";
import { getOrderEventTimelineItems, getTimelineItems, Timeline } from "common/components/Timeline";
import { getDeliveryTimeTz } from "common/helpers/OrderEvents/helpers/timezones";
import { CarrierOnlyEstimationData } from "~/common/types/CarrierOnlyEstimationData";
import { getShippingMethod } from "~/common/utils/carrier";
import { getDeliveryImageUrl } from "~/common/utils/tracking/getDeliveryImageUrl";
import { ShipmentItems } from "../shipment/ShipmentItems";
import { Shipments } from "../shipment/Shipments";

export interface OrderPageProps {
  deserializedOrder?: DeserializedOrderSummary;
  sellerUrl?: string;
  trackingDetailMap?: MappedTrackingDetail;
  parcelTrackingDetails?: TrackingDetailResponse;
  carrierOnlyEstimationData?: CarrierOnlyEstimationData;
}

export const OrderPage: React.FC<OrderPageProps> = ({
  deserializedOrder,
  sellerUrl,
  trackingDetailMap,
  parcelTrackingDetails,
  carrierOnlyEstimationData,
}) => {
  const order = deserializedOrder?.order;
  const events = deserializedOrder?.events;
  const destinationTz = order ? getDeliveryTimeTz(order.destinationTz) : getDeliveryTimeTz(undefined);
  const timelineItems = deserializedOrder
    ? getOrderEventTimelineItems(deserializedOrder.events)
    : parcelTrackingDetails
    ? getTimelineItems(parcelTrackingDetails, { estimatedDeliveryTime: undefined, estimatedShipTime: undefined }, [])
    : [];

  datadogRum.addTiming("next_order_page");

  const detailMarkup =
    order && trackingDetailMap && events ? (
      order.status === OrderStatus.CANCELLED ? (
        <>
          <Timeline items={timelineItems} destinationTz={destinationTz} />
          <ShipmentItems
            items={order.originalItems}
            originalItems={order.originalItems}
            destinationTz={destinationTz}
          />
        </>
      ) : (
        <Shipments
          shipments={order.shipments}
          originalItems={order.originalItems}
          destinationTz={destinationTz}
          trackingDetailMap={trackingDetailMap}
          orderEvents={events}
          carrierOnlyEstimationData={carrierOnlyEstimationData}
        />
      )
    ) : (
      <Timeline items={timelineItems} destinationTz={destinationTz} />
    );

  return (
    <Frame>
      <Box background="surface" minHeight="100%" paddingBlockEnd="12">
        {deserializedOrder && <TopBar order={deserializedOrder.order} sellerUrl={sellerUrl} />}
        <Inline align="center">
          <Box maxWidth="680px" paddingBlockStart="5">
            <Layout>
              <Layout.Section>
                <OrderStatusCard
                  deserializedOrder={deserializedOrder}
                  parcelTrackingDetails={parcelTrackingDetails}
                  trackingDetailMap={trackingDetailMap}
                  carrierOnlyEstimationData={carrierOnlyEstimationData}
                />
              </Layout.Section>
              <Layout.Section>{detailMarkup}</Layout.Section>
              {/* Adds the Delivery Method + Carrier Tracking Link for Unbundled Parcel Tracking */}
              {parcelTrackingDetails && (
                <Layout.Section>
                  <DeliveryMethod
                    trackingCode={parcelTrackingDetails.trackingCode}
                    shippingMethod={getShippingMethod(parcelTrackingDetails.trackingCode, parcelTrackingDetails)}
                    deliveryImageUrl={getDeliveryImageUrl(parcelTrackingDetails)}
                  />
                </Layout.Section>
              )}
            </Layout>
          </Box>
        </Inline>
      </Box>
    </Frame>
  );
};
