import { AlphaStack, Box, BoxProps, Inline, Text } from "@shopify/polaris";
import { getProgressIcon } from "common/utils/order/status";
import { useHydrated } from "react-hydration-provider";

interface StatusCardProps {
  heading: string;
  background: BoxProps["background"];
  body: string;
  icon: string;
  step: number;
  isToday: boolean;
  numSteps?: number;
}

export function StatusCard({ heading, background, body, icon, step, isToday, numSteps = 4 }: StatusCardProps) {
  const hydrated = useHydrated();
  const bodyText = hydrated ? body : "...";

  if (!heading) {
    return null;
  }

  const timelineMarkup = Array.from({ length: numSteps }, (_, index) => {
    const stepIcon = getProgressIcon(step, index);

    return <img src={stepIcon} alt="alt" key={index} />;
  });
  const bodyMarkup = isToday ? (
    <Text as="span" variant="bodyMd" color="success" fontWeight="semibold">
      {bodyText}
    </Text>
  ) : (
    <Text as="span" variant="bodyMd">
      {bodyText}
    </Text>
  );

  return (
    <Box borderRadius="5" background={background} padding="10">
      <AlphaStack gap="8">
        <Box width="100%">
          <Inline align="space-between" gap="10" wrap={false}>
            <AlphaStack gap="2">
              <Text as="h1" variant="heading3xl" fontWeight="bold">
                {heading}
              </Text>
              {bodyMarkup}
            </AlphaStack>
            <img src={icon} alt={heading} />
          </Inline>
        </Box>
        <Inline gap="2" wrap={false}>
          {timelineMarkup}
        </Inline>
      </AlphaStack>
    </Box>
  );
}
